<template>
  <el-container class="father">
    <!-- 左侧菜单栏 -->
    <el-aside>
      <el-menu
        :default-active="this.$route.path"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        unique-opened
        router
      >
        <!-- <el-submenu :index="item.path" v-for="(item, index) in menuList" :key="index">
          <template slot="title">
            <i class="el-icon-location"></i>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item-group v-if="item.children.length > 0">
            <el-menu-item :index="k.path" v-for="(k, v) in item.children" :key="v">{{ k.title }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>-->
        <el-submenu :index="item.menuUrl + ''" v-for="(item, i) in menuList" :key="i">
          <template slot="title">
            <i class="el-icon-folder-opened"></i>
            <span>{{ item.menuName }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              :index="k.menuUrl + ''"
              v-for="(k, v) in item.twoMenusList"
              :key="v"
              >{{ k.menuName }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-aside>
    <!-- 右侧内容区域 -->
    <el-main>
      <!-- <div>
        <el-input v-model="msg" placeholder=""></el-input>
        <el-button @click="send" type="primary">发送</el-button>
        <div id="message"></div>
      </div> -->
      <router-view />
    </el-main>

  </el-container>
</template>

<script>
export default {
  name: 'system',
  components: {},
  data () {
    return {
      menuList: [
        {
          title: '系统设置',
          path: '/system/settings',
          icon: '',
          children: [
            { title: '应用管理', path: '/system/settings/applica', children: [] },
            { title: '用户管理', path: '/system/settings/userManage', children: [] },
            { title: '菜单管理', path: '/system/settings/menuManage', children: [] },
            { title: '角色管理', path: '/system/settings/characterManage', children: [] },
            { title: '日志管理', path: '/system/settings/diaryManage', children: [] }
          ]
        },
        {
          title: '订单管理',
          path: '/system/orderlist',
          children: [
            { title: '实时订单', path: '/system/orderlist/instantOrder', children: [] },
            { title: '订单查看', path: '/system/orderlist/selectOrder', children: [] },
            { title: '预约订单', path: '/system/orderlist/bookingOrder', children: [] },
            {
              title: '订单随车人员记录',
              path: '/system/orderlist/orderFollowPeo',
              children: []
            },
            {
              title: '订单其他费用记录',
              path: '/system/orderlist/orderCost',
              children: []
            },
            {
              title: '订单用药记录',
              path: '/system/orderlist/orderPharmacy',
              children: []
            },
            { title: '订单支付记录', path: '/system/orderlist/orderPay', children: [] },
            {
              title: '订单退款记录',
              path: '/system/orderlist/orderRefund',
              children: []
            },
            {
              title: '订单统计管理',
              path: '/system/orderlist/orderStatistics',
              children: []
            }
          ],
          icon: ''
        },
        {
          title: '机构管理',
          path: '/system/organization',
          icon: '',
          children: [
            {
              title: '机构信息管理',
              path: '/system/organization/orManagement',
              children: []
            },
            {
              title: '机构车辆管理',
              path: '/system/organization/orCarManagement',
              children: []
            }
          ]
        },
        {
          title: '车辆管理',
          path: '/system/car',
          children: [
            { title: '车辆信息管理', path: '/system/car/carInfo', children: [] },
            { title: '车主管理', path: '/system/car/ownermanagement', children: [] },
            {
              title: '车辆历史状态信息',
              path: '/system/car/carhistoricalstate',
              children: []
            },
            {
              title: '车辆上下线状态信息管理',
              path: '/system/car/carLineManagement',
              children: []
            },
            {
              title: '车辆年检信息管理',
              path: '/system/car/carannualinspectioninformation',
              children: []
            },
            {
              title: '车辆维修信息管理',
              path: '/system/car/carmaintenanceinformation',
              children: []
            },
            {
              title: '车辆保险信息管理',
              path: '/system/car/carinsuranceinformation',
              children: []
            },
            // { title: '车辆实时状态管理', path: '/system/car/carrealtimestatus', children: [] },
            {
              title: '车辆消毒管理',
              path: '/system/car/carDisinfectManagement',
              children: []
            }
          ],
          icon: ''
        },
        {
          title: '车载设备管理',
          path: '/system/mobileUnit',
          children: [
            {
              title: '车载设备信息管理',
              path: '/system/mobileUnit/vehicleequipment',
              children: []
            },
            {
              title: '车载设备入库记录',
              path: '/system/mobileUnit/vehicleequipmentWarehousing',
              children: []
            },
            {
              title: '车载设备库存记录',
              path: '/system/mobileUnit/vehicleequipmentstock',
              children: []
            },
            {
              title: '车载设备出库记录',
              path: '/system/mobileUnit/vehicleequipmentDelivery',
              children: []
            },
            {
              title: '车载设备维修管理',
              path: '/system/mobileUnit/vehicleequipmentrepair',
              children: []
            },
            {
              title: '车载设备报废管理',
              path: '/system/mobileUnit/vehicleequipmentscrap',
              children: []
            },
            {
              title: '车载硬盘数据备份记录管理',
              path: '/system/mobileUnit/vehicleequipmenthdd',
              children: []
            },
            {
              title: '医用设备（含药品）信息管理',
              path: '/system/mobileUnit/Medicalproduct',
              children: []
            }
          ],
          icon: ''
        },
        {
          title: '随车人员管理',
          path: '/system/onBoard',
          children: [
            {
              title: '随车人员实时状态管理',
              path: '/system/onBoard/realtime',
              children: []
            },
            {
              title: '随车人员历史状态管理',
              path: '/system/onBoard/history',
              children: []
            },
            // {title: "随车人员培训信息管理",path: "/system/onBoard/train",children: []},
            {
              title: '随车人员证书信息管理',
              path: '/system/onBoard/certificate',
              children: []
            }
          ],
          icon: ''
        },
        // {
        //   title: "培训及证书医用设备管理",
        //   path: "/system/Program",
        //   children: [
        //     // {title: "培训课目信息表",path: "/system/Program/TrainingProgram",children: []},
        //     { title: "证书信息管理", path: "/system/Program/CertificateInformation", children: [] },
        //     { title: "医用设备（含药品）信息管理", path: "/system/Program/MedicalEquipment", children: [] },
        //   ], icon: ""
        // },
        {
          title: '安全准入管理',
          path: '/system/safety',
          icon: '',
          children: [
            {
              title: '证书信息管理',
              path: '/system/safety/CertificateInformation',
              icon: '',
              children: []
            },
            {
              title: '随车人员证书管理',
              path: '/system/safety/certificate',
              icon: '',
              children: []
            },
            {
              title: '随车人员培训记录管理',
              path: '/system/safety/trainingRecord',
              icon: '',
              children: []
            },
            {
              title: '随车人员培训科目信息',
              path: '/system/safety/trainingCourse',
              icon: '',
              children: []
            }
          ]
        },
        { title: '财务管理', path: '', icon: '', children: [] },
        {
          title: '基础设置',
          path: '/system/basicSetting',
          icon: '',
          children: [
            {
              title: '转运类型管理',
              path: '/system/basicSetting/transportType',
              icon: '',
              children: []
            },
            {
              title: '转运车类型管理',
              path: '/system/basicSetting/cartype',
              icon: '',
              children: []
            },
            {
              title: '转运收费标准管理',
              path: '/system/basicSetting/TransshipmentCharges',
              icon: '',
              children: []
            },
            {
              title: '转运预付费管理',
              path: '/system/basicSetting/ProportionPrepaid',
              icon: '',
              children: []
            },
            {
              title: '调度员区域关联管理',
              path: '/system/basicSetting/dispatcherArea',
              icon: '',
              children: []
            },
            {
              title: '车辆与车载关联管理',
              path: '/system/basicSetting/vehicleboardassociation',
              icon: '',
              children: []
            }
          ]
        }, // 基础设置
        {
          title: '人员管理',
          path: '',
          icon: '',
          children: [
            {
              title: '驾驶员管理',
              path: '/system/peoManagement/cardministration',
              icon: '',
              children: []
            },
            {
              title: '医护人员管理',
              path: '/system/peoManagement/mediclist',
              icon: '',
              children: []
            },
            {
              title: '随车人员历史状态信息',
              path: '/system/peoManagement/peohistoristate',
              icon: '',
              children: []
            },
            {
              title: '随车人员上下线状态信息管理',
              path: '/system/peoManagement/peoLineManagement',
              icon: '',
              children: []
            }
          ]
        } // 人员管理
      ],

      msg: ''

    }
  },
  created () {
  },
  mounted () {
    // setTimeout(() => {
    this.getMenuList()

    // }, 0);

    // setTimeout(() => {
    //   const test = {
    //     data: '{"category":1,"content":"请点击查看","id":40,"title":"您有一条新的调度订单"}'
    //   }
    //   this.getMessage(test)
    // }, 5000)
  },

  methods: {
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
      if (this.$route.path === keyPath[0]) return
      this.$router.push(keyPath[0])
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    getMenuList () {
      this.menuList = JSON.parse(sessionStorage.getItem('menuList'))
    }

  }

}
</script>
<style lang="less" scoped>
.father {
  height: 100%;
  /deep/ .el-aside {
    width: 220px !important;
    .el-menu {
      height: 100%;
    }
  }
  .el-main {
    height: 100%;
    width: 100%;
    padding: 10px;
    // display: flex;
    // overflow-y: scroll;
  }
}

</style>
